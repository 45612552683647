import Vue from 'vue'

export const state = {
  communes: []
}
export const getters = {
  communes: ({ communes }) => communes
}
export const actions = {
  async getCommunes({ commit }) {
    try {
      const response = (await Vue.axios.get(`/commune/`)).data
      commit('setCommunes', response)
    } catch (error) {
      return error
    }
  }
}
export const mutations = {
  setCommunes(vuexState, payload) {
    vuexState.communes = payload
  }
}
